import React from "react";

export const FilterContext = React.createContext();

export function FilterProvider({ children }) {
    const [openFilter, setOpenFilter] = React.useState(false);
    const [filter, setFilter] = React.useState([]);

    const handleFilter = (value) => {
        setFilter((prev) =>
            prev.includes(value)
                ? prev.filter((item) => item !== value)
                : [...prev, value]
        );
    };

    const matchesFilter = React.useCallback(
        (item) => {
            if (!filter.length) return true; // Si le filtre est vide, afficher tout
            return filter.some((f) => item.labels.includes(f));
        },
        [filter]
    );

    return (
        <FilterContext.Provider
            value={{
                filter,
                handleFilter,
                matchesFilter,
                openFilter,
                setOpenFilter,
            }}
        >
            {children}
        </FilterContext.Provider>
    );
}
/*
export function useFilter() {
    return React.useContext(FilterContext);
}
*/
