import React from "react";
//Mui Components
import Skeleton from "@mui/material/Skeleton";
import CardMedia from "@mui/material/CardMedia";
//get file from storage
import { getContentIllustration } from "storage/blobStorage";
//get categorie ressource
import { useLocation } from "react-router-dom";

export default function IllustrationRessource({
    illustration,
    height,
    width,
    categorie,
}) {
    const [isLoaded, setIsLoaded] = React.useState(false);
    const [cover, setCover] = React.useState();
    const location = useLocation().pathname;
    const [type, setType] = React.useState();

    React.useEffect(() => {
        categorie
            ? setType(categorie)
            : setType(location.includes("Video") ? "Video" : "Anatomie3D");
    }, [categorie, location]);

    React.useEffect(() => {
        if (type) {
            const srcCover = getContentIllustration(type, illustration);
            setCover(srcCover);
        }
    }, [type, illustration, location]);

    return React.useMemo(() => {
        return (
            <>
                {!isLoaded && (
                    <Skeleton
                        variant="rectangular"
                        animation="wave"
                        height={height ? height : type ? 210 : 250}
                        width={"100%"}
                    />
                )}

                <CardMedia
                    image={cover}
                    component="img"
                    onLoad={() => setIsLoaded(true)}
                    sx={{
                        width: width ? width : "100%",
                        maxWidth: "900px",
                        mx: "auto",
                        display: isLoaded ? "block" : "none",
                    }}
                />
            </>
        );
    }, [type, isLoaded, cover, height, width]);
}
