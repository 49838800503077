//Mui Components
import { Skeleton, SvgIcon } from "@mui/material";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import Typography from "@mui/material/Typography";

export default function Titre({ actionButton, children, icon }) {
    return (
        <Box>
            <Box
                display="flex"
                flexDirection="row"
                justifyContent="space-between"
                alignItems="center"
                mx={1}
            >
                <Typography
                    variant="h4"
                    sx={{
                        fontWeight: 400,
                        lineHeight: 1.334,
                        display: "flex",
                        letterSpacing: "0em",
                        color: "primary.main",
                        mt: 1,
                    }}
                >
                    {icon && (
                        <SvgIcon
                            sx={{
                                my: "auto",
                                fontSize: "inherit",
                                color: "inherit",

                                mr: 1,
                            }}
                            component={icon}
                        />
                    )}

                    {children ? children : <Skeleton width={300} />}
                </Typography>

                {actionButton}
            </Box>
            <Divider
                sx={{
                    borderColor: "secondary.main",
                    borderWidth: 1,
                    width: "5%",
                    marginLeft: 5,
                }}
            />
        </Box>
    );
}
