import React from "react";
//Mui Components
import Grid2 from "@mui/material/Unstable_Grid2/Grid2";
import LinearProgress from "@mui/material/LinearProgress";
//Custom Components
import CardRessource from "./CardRessource";
//Filter Context
import { FilterContext } from "contexts/FilterContext";
//Get Ressources
import { useLoaderData } from "react-router-dom";

export default function SummaryRessources() {
    const content = useLoaderData();
    const { matchesFilter } = React.useContext(FilterContext);

    if (content) {
        return (
            <Grid2
                container
                width={"100%"}
                mx="auto"
                mt={1}
                columnSpacing={{
                    xs: 0,
                    sm: 2,
                    md: 5,
                    lg: 5,
                    xl: 5,
                }}
                rowSpacing={{
                    xs: 1,
                    sm: 5,
                    md: 5,
                    lg: 5,
                    xl: 5,
                }}
                xs={12}
            >
                {content.filter(matchesFilter).map((item) => (
                    <Grid2 key={item.name} xs={12} sm={6} md={3}>
                        <CardRessource data={item} />
                    </Grid2>
                ))}
            </Grid2>
        );
    }

    return <LinearProgress />;
}
