import React from "react";
//Mui Components

import Stack from "@mui/material/Stack";
import Skeleton from "@mui/material/Skeleton";
//Custom Components
import Titre from "../Typographie/Titre";
import RessourcesSummary from "./SummaryRessource";
//Get the routes informations
import { useLocation } from "react-router-dom";
import router from "router/router";
//Ressources Filter
import { FilterProvider } from "contexts/FilterContext";
import RessourcesFilter from "./FilterRessource";
import FilterButton from "../Button/FilterButton";

export default function ContentPage() {
    const [page, setPage] = React.useState(null);
    const location = useLocation().pathname;

    React.useEffect(() => {
        router.routes[0].children.map(
            (item) =>
                "/" + item.path === location &&
                setPage({
                    name: item.name,
                    icon: item.icon,
                    type: item.path,
                })
        );
    }, [location]);

    if (page)
        return (
            <Stack>
                <FilterProvider>
                    <Titre
                        icon={page.icon}
                        actionButton={
                            page.name === "Vidéo" && (
                                <FilterButton
                                    variant="outlined"
                                    color="tertiary"
                                    size="small"
                                />
                            )
                        }
                    >
                        {page.name}
                    </Titre>
                    {page.name === "Vidéo" && <RessourcesFilter />}
                    <RessourcesSummary type={page && page.type} />
                </FilterProvider>
            </Stack>
        );
    else
        return (
            <Stack p={2}>
                <Skeleton />
                <Skeleton variant="rounded" height={250} />
            </Stack>
        );
}
