import React from "react";
import { createRoot } from "react-dom/client";

// Provider Auth EntraID
import { MsalProvider } from "@azure/msal-react";
import instanceMSAL from "auth/msalConfig";

// Provider Theme Material UI
import ThemeProvider from "@mui/material/styles/ThemeProvider";
import CssBaseline from "@mui/material/CssBaseline/CssBaseline";
import { theme } from "theme/theme";
import "theme/style.css";

// Provider Router App
import { RouterProvider } from "react-router-dom";
import router from "router/router";

//Function pour notifier l'installation de la PWA
import * as serviceWorkerRegistration from "./serviceWorkerRegistration";

function Index() {
    return (
        <MsalProvider instance={instanceMSAL}>
            <ThemeProvider theme={theme}>
                <CssBaseline />
                <RouterProvider router={router} />
            </ThemeProvider>
        </MsalProvider>
    );
}

const rootDom = document.getElementById("root");
const root = createRoot(rootDom);
root.render(<Index />);

// Enregistrer le service worker pour activer PWA
serviceWorkerRegistration.register();
