import { createBrowserRouter } from "react-router-dom";
import { loaderAnatomie3D, loaderRessources, loaderVideo } from "./loader";

//Imports Routes
import App from "routes/App";
import DashBoard from "routes/Dashboard";
import Profil from "routes/Profil";
import PageRessource from "components/Resources/PageRessource";
import Ressource from "routes/Ressource";
import { VideoProvider } from "contexts/VideoContext";
import { AnatomieProvider } from "contexts/AnatomieContext";

//Imports Icons
import HomeIcon from "@mui/icons-material/Home";
import VideoLibraryOutlinedIcon from "@mui/icons-material/VideoLibraryOutlined";
import ViewInArSharpIcon from "@mui/icons-material/ViewInArSharp";
import AccountCircleOutlinedIcon from "@mui/icons-material/AccountCircleOutlined";

const handleContent = () => {
    return true;
};

const router = createBrowserRouter([
    {
        path: "/",
        element: <App />,
        errorElement: <App />,
        loader: () => loaderRessources("Global"),
        children: [
            {
                index: true,
                path: "/",
                element: <DashBoard />,
                loader: () => loaderRessources("Global"),
                //
                name: "Tableau de bord",
                icon: HomeIcon,
                //
                footer: true,
                menu: true,
            },
            {
                path: "Video",
                //
                name: "Vidéo",
                icon: VideoLibraryOutlinedIcon,
                //
                navbar: true,
                footer: true,
                //
                children: [
                    {
                        index: true,
                        element: <PageRessource />,
                        loader: () => loaderRessources("Video"),
                    },
                    {
                        path: ":ressource",
                        element: (
                            <Ressource>
                                <VideoProvider />
                            </Ressource>
                        ),
                        loader: loaderVideo,
                    },
                ],
            },
            {
                path: "Anatomie3D",
                //
                name: "Anatomie 3D",
                icon: ViewInArSharpIcon,
                //
                navbar: true,
                footer: true,
                //
                shouldRevalidate: handleContent,
                children: [
                    {
                        index: true,
                        element: <PageRessource />,
                        loader: () => loaderRessources("Anatomie3D"),
                    },
                    {
                        path: ":ressource",
                        element: (
                            <Ressource>
                                <AnatomieProvider />
                            </Ressource>
                        ),
                        loader: loaderAnatomie3D,
                    },
                ],
            },
            {
                path: "profil",
                element: <Profil />,
                //
                name: "Profil",
                icon: AccountCircleOutlinedIcon,
                //
                navbar: true,
                footer: true,
            } /*
            {
                path: "gestionnaire",
                element: <Gestionnaire />,
                //
                name: "Gestionnaire",
                icon: SettingsIcon,
                //
                navbar: true,
                footer: false,
                //
                children: [
                    {
                        index: true,
                        element: (
                            <>
                                <Contenus />
                                <Users />
                            </>
                        ),
                    },
                    {
                        path: "ressources",
                        element: <Contenus />,
                        children: [
                            {
                                path: "ajouter",
                                element: <NotAvailaible />,
                                //
                                name: "Ajouter du contenus",
                                icon: AddCircleOutlineRoundedIcon,
                            },
                            {
                                path: "modifier",
                                element: <NotAvailaible />,
                                //
                                name: "Modifier du contenus",
                                icon: EditRoundedIcon,
                            },
                            {
                                path: "supprimer",
                                element: <NotAvailaible />,
                                //
                                name: "Supprimer du contenus",
                                icon: DeleteRoundedIcon,
                            },
                            {
                                path: "liste",
                                element: <ListeContenus />,
                                //
                                name: "Liste du contenus",
                                icon: FormatListBulletedRoundedIcon,
                            },
                        ],
                    },
                    {
                        path: "users",
                        element: <Users />,
                        children: [
                            {
                                path: "liste",
                                element: <ListUsers />,
                                //
                                name: "Voir tous les utilisateurs",
                                icon: PeopleAltRoundedIcon,
                            },
                            {
                                path: "database",
                                element: <Database />,
                                //
                                name: "Gestion Base de donnée",
                                icon: MemoryRoundedIcon,
                            },
                        ],
                    },
                ],
            },*/,
        ],
    },
]);

export default router;
