import React from "react";
//Get Video data & Navigate router
import { useLoaderData, useLocation, useNavigate } from "react-router-dom";
//Get Chapitre & Video & MAJ Views Video
import useChapitresVideo from "hooks/useVideoChapitres";
import { getBlobVideo } from "storage/blobStorage";
import { tableVideos } from "storage/tableStorage";
//Fullscreen Functions
import { exitFullScreenVideo, openVideo } from "utils/videoPlayer";

//Custom Components
import TabsContentRessource from "components/Resources/TabsContentRessource";
import ChapitreVideoCard from "components/Cards/ChapitreVideoCard";

export const VideoContext = React.createContext();

export function VideoProvider() {
    const video = useLoaderData();
    const chapitres = useChapitresVideo("ChapitresVideo" + video.rowKey);
    const location = useLocation();
    const videoRef = React.useRef();
    const navigate = useNavigate();

    React.useEffect(() => {
        videoRef && exitFullScreenVideo(videoRef);
        videoRef && navigate(".", { state: { play: false } });
    }, [videoRef, navigate]);

    React.useEffect(() => {
        location.state.play && openVideo(videoRef.current);
    }, [location]);

    const [viewed, setViewed] = React.useState(false);

    const handleTimeUpdate = (event) => {
        const currentTime = event.target.currentTime;

        // Si l'utilisateur a regardé plus de 1 minute et que la vue n'a pas encore été comptée
        if (currentTime > 60 && !viewed) {
            setViewed(true);
            const action = [
                [
                    "update",
                    {
                        partitionKey: video.partitionKey,
                        rowKey: video.rowKey,
                        views: video.views + 1,
                    },
                    "Merge",
                ],
            ];
            tableVideos.submitTransaction(action);
        }
    };

    return (
        <VideoContext.Provider value={{}}>
            <video
                width={1}
                onTimeUpdate={handleTimeUpdate}
                src={getBlobVideo(video.fileName)}
                ref={videoRef}
                controls
                controlsList="nodownload"
                style={{ zIndex: -1, position: "absolute" }}
            />
            <TabsContentRessource
                description={video.description}
                onglet={"Chapitres"}
            >
                {chapitres.map((chap) => (
                    <ChapitreVideoCard
                        key={chap.name}
                        item={chap}
                        play={openVideo}
                        videoRef={videoRef}
                    />
                ))}
            </TabsContentRessource>
        </VideoContext.Provider>
    );
}
