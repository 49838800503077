import React from "react";
//Mui Components
import Card from "@mui/material/Card";
import CardActionArea from "@mui/material/CardActionArea";
import Chip from "@mui/material/Chip";
import Typography from "@mui/material/Typography";
//Custom Components
import HeaderRessource from "./HeaderRessource";
import IllustrationRessource from "./IllustrationRessource";
//Navigate Router
import { useNavigate } from "react-router-dom";

export default function CardRessource({ data }) {
    const navigate = useNavigate();

    const handleNavigation = () =>
        navigate("./" + data.rowKey, { state: { play: false } });

    if (data)
        return (
            <Card variant="outlined" square>
                <HeaderRessource
                    name={data.name}
                    id={data.rowKey}
                    tags={data.labels.split(",")}
                />
                <CardActionArea onClick={handleNavigation}>
                    <IllustrationRessource illustration={data.coverFileName} />
                    <Chip
                        label={
                            data.size
                                ? "Durée " + data.size
                                : data.childrenCount.split(",").length +
                                  1 +
                                  " Morceaux 3D"
                        }
                        size="small"
                        sx={{
                            position: "absolute",
                            bottom: 5,
                            bgcolor: "#000000",
                            color: "whitesmoke",
                            right: 5,
                            opacity: 0.5,
                        }}
                    />
                    <Typography
                        variant="subtitle2"
                        sx={{
                            position: "absolute",
                            bottom: 0,
                            left: 0,
                            p: 0.5,
                            pr: 3,
                            fontStyle: "italic",
                            borderTopRightRadius: 10,
                            background:
                                "linear-gradient(90deg, rgba(255,255,255,0.8) 50%, rgba(255,255,255,0) 100%)",
                        }}
                    >
                        {data.views ? data.views : "0"} vues
                    </Typography>
                </CardActionArea>
            </Card>
        );
}
