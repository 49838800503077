import Stack from "@mui/material/Stack";
import Skeleton from "@mui/material/Skeleton";
import Titre from "../components/Typographie/Titre";
import React from "react";
import { UserDataContext } from "../lib/contexts/UserDataContext";
import Alert from "@mui/material/Alert";
import SpotlightRessourceCard from "../components/Cards/SpotlightRessourceCard";
import { useLoaderData } from "react-router-dom";
import SmallCardRessource from "../components/Resources/SmallCardRessource";

export default function Test() {
    const ressources = useLoaderData();
    const [spotlight, setSpotlight] = React.useState(null);
    const { userData, favorisAnatomie3D, favorisVideo } =
        React.useContext(UserDataContext);

    React.useEffect(() => {
        //Affiche le numéro de la semaine en cours
        function dateWeek() {
            const d = new Date();
            d.setHours(0, 0, 0, 0);
            d.setDate(d.getDate() + 3 - ((d.getDay() + 6) % 7));
            var w = new Date(d.getFullYear(), 0, 4);
            return (
                "0" +
                (1 +
                    Math.round(
                        ((d.getTime() - w.getTime()) / 86400000 -
                            3 +
                            ((w.getDay() + 6) % 7)) /
                            7
                    ))
            ).slice(-2);
        }

        function mapRange(value, inMin, inMax, outMin, outMax) {
            return Math.round(
                ((value - inMin) * (outMax - outMin)) / (inMax - inMin) + outMin
            );
        }

        const mappedValue = mapRange(dateWeek(), 1, 52, 0, 27);

        if (ressources) {
            !spotlight && setSpotlight(ressources[mappedValue]);
        }
    }, [ressources, spotlight]);

    const [favoris, setFavoris] = React.useState(null);

    React.useEffect(() => {
        if (favorisAnatomie3D && favorisAnatomie3D) {
            setFavoris(favorisVideo.concat(favorisAnatomie3D));
        }
    }, [favorisAnatomie3D, favorisVideo]);

    if (spotlight && ressources && userData && favoris) {
        return (
            <Stack p={1} rowGap={1}>
                <Titre>À la une cette semaine </Titre>
                <SpotlightRessourceCard data={spotlight} />
                <Titre>Vos Favoris </Titre>
                {!favoris.length ? (
                    <Alert color="secondary" elevation={2} severity="info">
                        Vous n'avez pas de favoris ...
                    </Alert>
                ) : (
                    <Stack
                        direction="row"
                        flexWrap="nowrap"
                        overflow="scroll"
                        spacing={1}
                        py={1}
                    >
                        {ressources.map(
                            (item) =>
                                favoris.includes(item.rowKey) && (
                                    <SmallCardRessource
                                        key={item.name}
                                        data={item}
                                    />
                                )
                        )}
                    </Stack>
                )}
                <Titre>Les plus visionnées </Titre>
                <Stack
                    direction="row"
                    flexWrap="nowrap"
                    overflow="scroll"
                    spacing={1}
                    py={1}
                >
                    {ressources
                        .sort((a, b) => b.views - a.views)
                        .map((item) => (
                            <SmallCardRessource key={item.name} data={item} />
                        ))}
                </Stack>
            </Stack>
        );
    } else
        return (
            <Stack p={1} spacing={1}>
                <Skeleton variant="text" height={48} />
                <Skeleton variant="rounded" height={277} />
                <Skeleton variant="text" height={48} />
                <Skeleton variant="rounded" height={166} />
                <Skeleton variant="text" height={48} />
                <Skeleton variant="rounded" height={166} />
            </Stack>
        );
}

/*

export default function Dashboard() {
    const { liste } = useRessourcesList();
    const [spotlight, setSpotlight] = React.useState(null);
    const { userData, favorisAnatomie3D, favorisVideo } =
        React.useContext(UserDataContext);

    React.useEffect(() => {
        //Affiche le numéro de la semaine en cours
        function dateWeek() {
            const d = new Date();
            d.setHours(0, 0, 0, 0);
            d.setDate(d.getDate() + 3 - ((d.getDay() + 6) % 7));
            var w = new Date(d.getFullYear(), 0, 4);
            return (
                "0" +
                (1 +
                    Math.round(
                        ((d.getTime() - w.getTime()) / 86400000 -
                            3 +
                            ((w.getDay() + 6) % 7)) /
                            7
                    ))
            ).slice(-2);
        }

        function mapRange(value, inMin, inMax, outMin, outMax) {
            return Math.round(
                ((value - inMin) * (outMax - outMin)) / (inMax - inMin) + outMin
            );
        }

        const mappedValue = mapRange(dateWeek(), 1, 52, 0, 27);

        if (liste) {
            !spotlight && setSpotlight(liste[mappedValue]);
        }
    }, [liste, spotlight]);

    const [favoris, setFavoris] = React.useState(null);

    React.useEffect(() => {
        if (favorisAnatomie3D && favorisAnatomie3D) {
            setFavoris(favorisVideo.concat(favorisAnatomie3D));
        }
    }, [favorisAnatomie3D, favorisVideo]);

    if (spotlight && liste && userData && favoris) {
        return (
            <Stack p={1} rowGap={1}>
                <Titre>À la une cette semaine </Titre>
                <CardButton data={spotlight} />
                <Titre>Mes favoris</Titre>
                {!favoris.length ? (
                    <Alert color="secondary" elevation={2} severity="info">
                        Vous n'avez pas de favoris ...
                    </Alert>
                ) : (
                    <Stack
                        direction="row"
                        flexWrap="nowrap"
                        overflow="scroll"
                        spacing={1}
                        py={1}
                    >
                        {liste.map(
                            (item) =>
                                favoris.includes(item.rowKey) && (
                                    <CardIconPlay key={item.name} data={item} />
                                )
                        )}
                    </Stack>
                )}
                <Titre>Les plus visionnées</Titre>
                <Stack
                    direction="row"
                    flexWrap="nowrap"
                    overflow="scroll"
                    spacing={1}
                    py={1}
                >
                    {liste
                        .sort((a, b) => b.views - a.views)
                        .map((item) => (
                            <CardIconPlay key={item.name} data={item} />
                        ))}
                </Stack>
            </Stack>
        );
    } else
        return (
            <Stack p={1} spacing={1}>
                <Skeleton variant="text" height={48} />
                <Skeleton variant="rounded" height={277} />
                <Skeleton variant="text" height={48} />
                <Skeleton variant="rounded" height={166} />
                <Skeleton variant="text" height={48} />
                <Skeleton variant="rounded" height={166} />
            </Stack>
        );
}
*/
