//Mui Components
import Card from "@mui/material/Card";
import CardActionArea from "@mui/material/CardActionArea";
import Chip from "@mui/material/Chip";
import Divider from "@mui/material/Divider";
import Typography from "@mui/material/Typography";
import Grid2 from "@mui/material/Unstable_Grid2/Grid2";
//Custom Components
import IllustrationRessource from "../Resources/IllustrationRessource";
//Mui Icons
import PlayArrowRoundedIcon from "@mui/icons-material/PlayArrowRounded";

export default function ChapitreVideoCard({ item, play, videoRef }) {
    return (
        <Card variant="outlined" key={item.name} sx={{ my: 1 }}>
            <Grid2
                component={CardActionArea}
                container
                xs={12}
                alignItems="flex-start"
                onClick={() => play(videoRef.current, item.startAt)}
            >
                <Grid2
                    xs={5}
                    sx={{
                        position: "relative",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                    }}
                >
                    <IllustrationRessource
                        height={80}
                        illustration={item.coverFileName}
                    />
                    <PlayArrowRoundedIcon
                        sx={{
                            position: "absolute",
                            color: "white",
                            fontSize: 80,
                            opacity: 0.7,
                            borderColor: "primary.light",
                        }}
                    />
                </Grid2>

                <Grid2 xs={7} pl={1}>
                    <Typography
                        variant="h6"
                        fontSize={14}
                        overflow="hidden"
                        height={22}
                        color="primary.main"
                    >
                        {item.name}
                    </Typography>
                    <Divider variant="middle" />

                    <Typography component="p" variant="body" fontSize={12}>
                        {item.description}
                    </Typography>
                    <Chip
                        label={`
                            Début à 
                        ${
                            item.startAt < 60
                                ? item.startAt + " sec"
                                : Math.floor(item.startAt / 64) + " min"
                        }
                        `}
                        size="small"
                        sx={{
                            position: "absolute",
                            bottom: 5,
                            bgcolor: "#000000",
                            color: "whitesmoke",
                            right: 5,
                            opacity: 0.5,
                        }}
                    />
                </Grid2>
            </Grid2>
        </Card>
    );
}
