import React from "react";
import Typography from "@mui/material/Typography";
import Card from "@mui/material/Card";
import Grid2 from "@mui/material/Unstable_Grid2/Grid2";
import CardActionArea from "@mui/material/CardActionArea";
import IllustrationRessource from "components/Resources/IllustrationRessource";

export default function NomenclatureAnatomieCard({ item, play, selected }) {
    return (
        <Grid2 key={item.name} xs={6}>
            <Card
                variant={"outlined"}
                sx={{
                    borderColor: selected.rowKey === item.id && "info.main",
                }}
                //position="relative"
            >
                <CardActionArea onClick={(e) => play(e, item.id)}>
                    <Typography
                        variant="h6"
                        fontSize={14}
                        height={22}
                        color="primary.main"
                        position="absolute"
                        top={3}
                        left={5}
                    >
                        {item.name}
                    </Typography>

                    <IllustrationRessource
                        //height={80}
                        illustration={item.cover}
                    />
                </CardActionArea>
            </Card>
        </Grid2>
    );
}
