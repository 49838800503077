import Card from "@mui/material/Card";
import Box from "@mui/material/Box";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import React from "react";
import Button from "@mui/material/Button";
import { useNavigate } from "react-router-dom";
import IllustrationRessource from "../Resources/IllustrationRessource";

export default function SpotlightRessourceCard({ data }) {
    const nav = useNavigate();

    return (
        <Card sx={{ position: "relative" }} variant="outlined">
            <IllustrationRessource
                categorie={data.type}
                illustration={data.coverFileName}
            />
            <CardContent
                sx={{
                    position: "absolute",
                    bottom: 45,
                    p: "5px !important",
                    width: "100%",
                    background:
                        "linear-gradient(180deg, rgba(255,255,255,0) 0%, rgba(255,255,255,1) 27%)",
                }}
            >
                <Typography variant="h6">{data.name}</Typography>
                <Typography>
                    {data.description.substr(0, 83) + "..."}
                </Typography>
            </CardContent>
            <Box display="flex" justifyContent="center" p={1} mt={3}>
                <Button
                    variant="contained"
                    color="info"
                    fullWidth
                    onClick={() =>
                        nav(data.type + "/" + data.rowKey, {
                            state: { play: false },
                        })
                    }
                >
                    Découvrir
                </Button>
            </Box>
        </Card>
    );
}
