import Card from "@mui/material/Card";
import Typography from "@mui/material/Typography";
import React from "react";
import CardHeader from "@mui/material/CardHeader";
import CardActionArea from "@mui/material/CardActionArea";
import { useNavigate } from "react-router-dom";
import Chip from "@mui/material/Chip";
import IllustrationRessource from "./IllustrationRessource";

export default function SmallCardRessource({ data }) {
    const nav = useNavigate();

    return (
        <Card
            elevation={3}
            sx={{
                position: "relative",
                flex: "0 0 auto",
                height: "22vw",
                width: "40vw",
            }}
        >
            <CardActionArea
                onClick={() =>
                    nav(data.type + "/" + data.rowKey, {
                        state: { play: false },
                    })
                }
            >
                <Chip
                    label={data.type}
                    size="small"
                    sx={{
                        position: "absolute",
                        top: 2,
                        left: 2,
                        fontSize: 10,
                        height: 13,
                        bgcolor: "#000000",
                        color: "whitesmoke",
                        opacity: 0.5,
                    }}
                />
                <IllustrationRessource
                    width={data.type === "Video" ? "100%" : "57%"}
                    illustration={data.coverFileName}
                    categorie={data.type}
                />
                <CardHeader
                    sx={{
                        position: "absolute",
                        bottom: 0,
                        p: "5px !important",
                        width: "100%",
                        background:
                            "linear-gradient(180deg, rgba(255,255,255,0) 0%, rgba(255,255,255,1) 27%)",
                    }}
                    title={
                        <Typography fontSize={14} color={"primary.main"}>
                            {data.name}
                        </Typography>
                    }
                />
            </CardActionArea>
        </Card>
    );
}
