import {
    tableAnatomie3D,
    tableVideos,
    getEntityTable,
} from "storage/tableStorage";

export function loaderVideo({ params }) {
    const ressource = getEntityTable("RessourcesVideo", params.ressource);

    return ressource;
}

export function loaderAnatomie3D({ params }) {
    const ressource = getEntityTable("RessourcesAnatomie3D", params.ressource);

    return ressource;
}

export const loaderRessources = async (origine) => {
    const ressource = [];

    const listVideos = tableVideos.listEntities();
    const listAnatomie = tableAnatomie3D.listEntities();

    if (origine === "Video" || origine === "Global") {
        for await (const video of listVideos) {
            ressource.push(Object.assign(video, { type: "Video" }));
        }
    }

    if (origine === "Anatomie3D" || origine === "Global") {
        for await (const anatomie3D of listAnatomie) {
            ressource.push(Object.assign(anatomie3D, { type: "Anatomie3D" }));
        }
    }

    return ressource;
};
