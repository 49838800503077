import React from "react";

import { Outlet, useNavigate, useRouteError } from "react-router-dom";
import Container from "@mui/material/Container";
import useMediaQuery from "@mui/material/useMediaQuery";

import Header from "components/Layout/Header";
import Footer from "components/Layout/Footer";
import Navbar from "components/Layout/Navbar";

import {
    AuthenticatedTemplate,
    UnauthenticatedTemplate,
} from "@azure/msal-react";
import Auth from "components/Auth/Auth";
import InstallApp from "../components/Snackbar/InstallApp";
import { UserDataProvider } from "../lib/contexts/UserDataContext";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import Alert from "@mui/material/Alert";

export default function App() {
    const matches = useMediaQuery("(max-width:600px)");

    const error = useRouteError();
    const nav = useNavigate();

    return (
        <UserDataProvider>
            <Header />
            <AuthenticatedTemplate>
                {!matches && <Navbar />}
                <Container disableGutters maxWidth="false" sx={{ mb: 8 }}>
                    {!error ? (
                        <Outlet />
                    ) : (
                        <Stack justifyContent="center" spacing={2} p={3}>
                            <Alert
                                color="error"
                                variant="standard"
                                elevation={5}
                            >
                                Une erreur est survenue
                            </Alert>
                            <Button
                                onClick={() => nav("./")}
                                variant="contained"
                                color="info"
                                size="small"
                            >
                                Retour à l'acceuil
                            </Button>
                        </Stack>
                    )}
                </Container>
                {matches && <Footer />}
                <InstallApp />
            </AuthenticatedTemplate>
            <UnauthenticatedTemplate>
                <Auth />
            </UnauthenticatedTemplate>
        </UserDataProvider>
    );
}
