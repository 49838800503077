//Mui Components
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
//Mui Icons
import MoreVertRoundedIcon from "@mui/icons-material/MoreVertRounded";
//Get Favori from UserContext
import { UserDataContext } from "contexts/UserDataContext";
//Get location et navigate router
import { useLocation, useNavigate } from "react-router-dom";

import React from "react";

export default function ButtonActionsRessources({ id }) {
    const navigate = useNavigate();
    const location = useLocation().pathname;

    const {
        favorisVideo,
        setFavorisVideo,
        favorisAnatomie3D,
        setFavorisAnatomie3D,
    } = React.useContext(UserDataContext);

    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handlePlay = () => {
        setAnchorEl(null);
        location.includes(id)
            ? navigate("", { state: { play: true } })
            : navigate("./" + id, { state: { play: true } });
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleFavori = () => {
        setAnchorEl(null);
        if (location.includes("Video")) {
            setFavorisVideo((prev) =>
                favorisVideo.includes(id)
                    ? prev.filter((item) => item !== id)
                    : [...prev, id]
            );
        } else if (location.includes("Anatomie3D")) {
            setFavorisAnatomie3D((prev) =>
                favorisAnatomie3D.includes(id)
                    ? prev.filter((item) => item !== id)
                    : [...prev, id]
            );
        }
    };

    return (
        <>
            <IconButton onClick={handleClick}>
                <MoreVertRoundedIcon />
            </IconButton>
            <Menu anchorEl={anchorEl} open={open} onClose={handleClose}>
                <MenuItem onClick={handlePlay}>
                    {location.includes("Video") ? "Lecture" : "Visionner"}
                </MenuItem>
                <MenuItem onClick={() => handleFavori()}>
                    {favorisVideo &&
                    favorisVideo.concat(favorisAnatomie3D).includes(id)
                        ? "Retirer des favoris"
                        : "Ajouter aux favoris"}
                </MenuItem>
            </Menu>
        </>
    );
}
