import IconButton from "@mui/material/IconButton";
import SearchRoundedIcon from "@mui/icons-material/SearchRounded";
import React from "react";
import Dialog from "@mui/material/Dialog";
import useListContents from "hooks/useRessourcesList";
import Autocomplete from "@mui/material/Autocomplete";
import Box from "@mui/material/Box";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import DialogContent from "@mui/material/DialogContent";
import InputBase from "@mui/material/InputBase";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListSubheader from "@mui/material/ListSubheader";
import ListItemText from "@mui/material/ListItemText";
import Chip from "@mui/material/Chip";
import VideoLibraryOutlinedIcon from "@mui/icons-material/VideoLibraryOutlined";
import ViewInArSharpIcon from "@mui/icons-material/ViewInArSharp";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import { getContentIllustration } from "../../lib/storage/blobStorage";
import Avatar from "@mui/material/Avatar";
import { useNavigate } from "react-router-dom";
import { AuthenticatedTemplate } from "@azure/msal-react";

export default function Search() {
    const [open, setOpen] = React.useState(false);
    const handleOpen = () => setOpen(!open);
    const navigate = useNavigate();
    const data = useListContents();

    const SearchContent = ({ children }) => (
        <DialogContent
            sx={{
                p: 0,
                width: "100%",
                background: "white",
                maxHeight: "74vh",
                "& .MuiAutocomplete-listbox": {
                    p: 0,
                    maxHeight: "100%",
                },
            }}
            dividers
        >
            {children}
        </DialogContent>
    );

    return (
        data && (
            <AuthenticatedTemplate>
                <IconButton
                    color="inherit"
                    onClick={handleOpen}
                    focusRipple={false}
                >
                    <SearchRoundedIcon />
                </IconButton>
                <Dialog
                    open={open}
                    fullWidth={true}
                    sx={{
                        "& .MuiDialog-container": {
                            alignItems: "flex-start",
                            mt: "3vh",
                        },
                    }}
                    PaperProps={{ sx: { height: "80vh", border: "none" } }}
                >
                    <Autocomplete
                        options={data && data.liste}
                        groupBy={(option) => option.categorie}
                        getOptionLabel={(option) => option.name}
                        disablePortal={true}
                        open={true}
                        sx={{ width: "100%" }}
                        renderInput={(params) => (
                            <Box
                                sx={{
                                    display: "flex",
                                    alignItems: "center",
                                    py: 0,
                                    px: 1,
                                    position: "relative",
                                }}
                                component="div"
                                ref={params.InputProps.ref}
                            >
                                <SearchRoundedIcon
                                    sx={{ fontSize: 30, color: "primary.main" }}
                                />

                                <InputBase
                                    inputProps={params.inputProps}
                                    placeholder="Que rechercez-vous ?"
                                    variant="standard"
                                    value="b"
                                    fullWidth
                                    sx={{ fontSize: 24 }}
                                />
                                <IconButton
                                    sx={{ position: "absolute", right: 1 }}
                                    onClick={handleOpen}
                                >
                                    <CloseRoundedIcon />
                                </IconButton>
                            </Box>
                        )}
                        renderGroup={(params) => (
                            <List
                                key={params.key}
                                subheader={
                                    <ListSubheader
                                        sx={{
                                            display: "flex",
                                            justifyContent: "center",
                                            m: 1,
                                        }}
                                    >
                                        <Chip
                                            label={params.group}
                                            color="info"
                                            sx={{
                                                p: 1,
                                                borderRadius: 3,
                                                width: "100%",
                                            }}
                                            icon={
                                                params.group === "Video" ? (
                                                    <VideoLibraryOutlinedIcon />
                                                ) : (
                                                    <ViewInArSharpIcon />
                                                )
                                            }
                                        />
                                    </ListSubheader>
                                }
                            >
                                {params.children}
                            </List>
                        )}
                        renderOption={(props, option) => {
                            const { key, ...optionProps } = props;
                            return (
                                <ListItem
                                    key={key}
                                    {...optionProps}
                                    onClick={() => {
                                        navigate(
                                            option.categorie + "/" + option.id,
                                            { state: { play: false } }
                                        );
                                        setOpen(false);
                                    }}
                                >
                                    <ListItemAvatar>
                                        <Avatar
                                            sx={{
                                                borderRadius: 0,
                                                background: "transparent",
                                                width:
                                                    option.categorie === "Video"
                                                        ? 80
                                                        : 80,
                                                height:
                                                    option.categorie === "Video"
                                                        ? 60
                                                        : 80,
                                                mr: 1,
                                            }}
                                        >
                                            <img
                                                alt={option.name}
                                                src={getContentIllustration(
                                                    option.categorie,
                                                    option.illustration
                                                )}
                                                width={"100%"}
                                            />
                                        </Avatar>
                                    </ListItemAvatar>
                                    <ListItemText primary={option.name} />
                                </ListItem>
                            );
                        }}
                        PaperComponent={SearchContent}
                    />
                </Dialog>
            </AuthenticatedTemplate>
        )
    );
}
