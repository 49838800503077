import React from "react";
import useUserData from "../hooks/useUserData";
import { TableUsers } from "../storage/tableStorage";

export const UserDataContext = React.createContext();

export function UserDataProvider({ children }) {
    const { userData } = useUserData();
    const [favorisVideo, setFavorisVideo] = React.useState(null);
    const [favorisAnatomie3D, setFavorisAnatomie3D] = React.useState(null);

    //Debug
    /*
    React.useEffect(
        () => console.log("Anatomie3D : " + favorisAnatomie3D),
        [favorisAnatomie3D]
    );
    React.useEffect(
        () => console.log("Video : " + favorisVideo),
        [favorisVideo]
    );*/

    React.useEffect(() => {
        function updateFavorisStorage() {
            if (userData && favorisAnatomie3D && favorisVideo) {
                const id = userData.nom + userData.prenom;
                const action = [
                    [
                        "update",
                        {
                            partitionKey: id,
                            rowKey: id,
                            favorisVideo: favorisVideo
                                ? favorisVideo.toString()
                                : "",
                            favorisAnatomie3D: favorisAnatomie3D
                                ? favorisAnatomie3D.toString()
                                : "",
                        },
                        "Merge",
                    ],
                ];
                TableUsers.submitTransaction(action);
            }
        }

        userData && updateFavorisStorage();
    }, [userData, favorisAnatomie3D, favorisVideo]);

    React.useEffect(() => {
        function initFavoris() {
            if (!favorisAnatomie3D && !favorisVideo) {
                setFavorisVideo(
                    userData.favorisVideo
                        ? userData.favorisVideo.split(",")
                        : []
                );
                setFavorisAnatomie3D(
                    userData.favorisAnatomie3D
                        ? userData.favorisAnatomie3D.split(",")
                        : []
                );
            }
        }

        userData && initFavoris();
    }, [userData, favorisAnatomie3D, favorisVideo]);

    return (
        <UserDataContext.Provider
            value={{
                userData,
                favorisAnatomie3D,
                favorisVideo,
                setFavorisAnatomie3D,
                setFavorisVideo,
            }}
        >
            {children}
        </UserDataContext.Provider>
    );
}

/*
import { blobUsersData } from "../storage/blobStorage";
import { TableUsers } from "../storage/tableStorage";


React.useEffect(() => {
        if (userData) {
            const id = userData.nom + userData.prenom;
            if (userData.register && !userJsonData) {
                const getBlobUserData = async () => {
                    try {
                        const blobClient = blobUsersData.getBlobClient(
                            id + "_Favoris.json"
                        );

                        const downloadBlockBlobResponse =
                            await blobClient.download();
                        const blobContent =
                            await downloadBlockBlobResponse.blobBody; // Utiliser blobBody()

                        // Lire le contenu du blob en tant que texte
                        const text = await blobContent.text();

                        // Parser le JSON
                        const parsedJson = JSON.parse(text);

                        // Mettre à jour le state avec les données JSON
                        setUserJsonData(parsedJson);
                    } catch (error) {
                        throw (
                            ("Erreur lors de la récupération du fichier JSON :",
                            error)
                        );
                    }
                };

                getBlobUserData();
            } else {
                const action = [
                    [
                        "update",
                        {
                            partitionKey: id,
                            rowKey: id,
                            register: true,
                        },
                        "Merge",
                    ],
                ];

                TableUsers.submitTransaction(action);

                const matrice = JSON.stringify({
                    favoris: {
                        video: [],
                        anatomie3D: [],
                    },
                });
                const newUserFile = new Blob([matrice], {
                    type: "application/json",
                });

                newUserFile &&
                    blobUsersData
                        .getBlockBlobClient(id + "_Favoris.json")
                        .uploadData(newUserFile);
            }
        }
    }, [userData, userJsonData]);*/
