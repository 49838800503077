import React from "react";
//Mui Components
import Typography from "@mui/material/Typography";
import Tabs from "@mui/material/Tabs";
import Divider from "@mui/material/Divider";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";

export default function TabsContentRessource({
    description,
    children,
    onglet,
}) {
    const [tab, setTab] = React.useState(0);

    const handleTabs = (event, newValue) => {
        setTab(newValue);
    };

    return (
        <Box mx={2}>
            <Tabs value={tab} onChange={handleTabs}>
                <Tab label="Description" />
                <Tab label={onglet} />
            </Tabs>
            <Divider sx={{ mb: 1 }} />
            {tab === 0 && <Typography>{description}</Typography>}
            {tab === 1 && children}
        </Box>
    );
}
