import React from "react";
//Mui Components
import AppBar from "@mui/material/AppBar";
import BottomNavigation from "@mui/material/BottomNavigation";
import BottomNavigationAction from "@mui/material/BottomNavigationAction";
import SvgIcon from "@mui/material/SvgIcon";
//Hook Router & location page
import { useNavigate } from "react-router-dom";
import { useSelectedPage } from "hooks/useSelectedPage";

export default function Footer() {
    const isSelect = useSelectedPage();
    const navTo = useNavigate();

    return (
        <AppBar
            color="inherit"
            position="fixed"
            elevation={24}
            sx={{ bottom: 0, top: "auto" }}
        >
            <BottomNavigation
                showLabels
                value={isSelect.selected}
                onChange={(event, newValue) => {
                    navTo(newValue);
                }}
            >
                {isSelect &&
                    isSelect.repertoire.map(
                        (route) =>
                            route.footer && (
                                <BottomNavigationAction
                                    key={route.path}
                                    label={route.name}
                                    icon={<SvgIcon component={route.icon} />}
                                    value={route.path}
                                    sx={{
                                        "& .MuiBottomNavigationAction-label": {
                                            fontSize: 9.48,
                                        },
                                        "& .MuiBottomNavigationAction-label.Mui-selected":
                                            {
                                                fontSize: 9.48,
                                            },
                                    }}
                                />
                            )
                    )}
            </BottomNavigation>
        </AppBar>
    );
}
