//MUI Components
import Button from "@mui/material/Button";
//MUI Icon
import FilterListIcon from "@mui/icons-material/FilterList";
//Filter Context
import { useContext } from "react";
import { FilterContext } from "../../lib/contexts/FilterContext";

export default function FilterButton({ ...props }) {
    const { setOpenFilter, openFilter } = useContext(FilterContext);

    return (
        <Button
            {...props}
            sx={{
                fontWeight: 900,
                height: "fit-content",
                p: "1px 5px",
                //boxShadow: 5,

                "& .MuiButton-startIcon": {
                    transitionDuration: "0.5s",
                    msTransitionProperty: "transform",
                    transform: openFilter ? "rotate(180deg)" : "rotate(0deg)",
                },
            }}
            startIcon={<FilterListIcon />}
            onClick={() => setOpenFilter(!openFilter)}
        >
            Filtre
        </Button>
    );
}
/*
 */
