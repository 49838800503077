import React from "react";
//Get Anatomie data & Navigate router
import { useLoaderData, useLocation, useNavigate } from "react-router-dom";

//Custom Components
import TabsContentRessource from "components/Resources/TabsContentRessource";

import { getEntityTable, tableAnatomie3D } from "storage/tableStorage";
import ModelViewer from "components/ModelViewer";
import NomenclatureAnatomieCard from "../../components/Cards/NomenclatureAnatomieCard";
import Grid2 from "@mui/material/Unstable_Grid2/Grid2";

export const AnatomieContext = React.createContext();

export function AnatomieProvider() {
    const anatomie = useLoaderData();
    const nav = useNavigate();
    const locationState = useLocation().state.play;

    const anatomie3D = useLoaderData();

    const handleModelViewer = (e, item) => {
        item && handleModele3D(item);
        return nav(".", { state: { play: !locationState } });
    };
    const [selectedMorceaux, setSelectedMorceaux] = React.useState(null);
    const handleModele3D = (id) => {
        getEntityTable("RepertoireModele3D", id).then((r) =>
            setSelectedMorceaux(r)
        );
    };

    React.useEffect(() => {
        if (!selectedMorceaux) {
            handleModele3D(anatomie3D.rowKey);
        }
    }, [selectedMorceaux, anatomie3D]);

    const [listMorceaux, setListeMorceaux] = React.useState([]);
    React.useEffect(() => {
        if (!listMorceaux.length) {
            setListeMorceaux([
                {
                    id: anatomie3D.rowKey,
                    name: anatomie3D.name,
                    cover: anatomie3D.coverFileName,
                },
            ]);
            anatomie3D.childrenCount.split(",").map((item) =>
                getEntityTable("RepertoireModele3D", item).then((r) =>
                    setListeMorceaux((prev) => [
                        ...prev,
                        {
                            id: r.rowKey,
                            name: r.name,
                            cover: r.fileCover,
                        },
                    ])
                )
            );
        }
    }, [listMorceaux, anatomie3D]);

    const [viewed, setViewed] = React.useState(false);
    React.useEffect(() => {
        const handleViews = () => {
            setViewed(true);
            const action = [
                [
                    "update",
                    {
                        partitionKey: anatomie3D.partitionKey,
                        rowKey: anatomie3D.rowKey,
                        views: anatomie3D.views ? anatomie3D.views + 1 : 1,
                    },
                    "Merge",
                ],
            ];
            tableAnatomie3D.submitTransaction(action);
            //onView(videoId);  // Appelle la fonction pour compter la vue
        };

        if (locationState && !viewed) {
            setTimeout(() => handleViews(), [30000]);
        }
    }, [viewed, locationState, anatomie3D]);

    return (
        <AnatomieContext.Provider value={{}}>
            <ModelViewer
                open={locationState}
                handle={handleModelViewer}
                modele3D={selectedMorceaux}
                listMorceaux={listMorceaux}
                handleModele3D={handleModele3D}
                selectedMorceaux={selectedMorceaux}
            />
            <TabsContentRessource
                description={anatomie.description}
                onglet={"Nomenclature"}
            >
                <Grid2 container xs={12} spacing={1} mx="auto" pb={5}>
                    {listMorceaux.map((item) => (
                        <NomenclatureAnatomieCard
                            key={item.name}
                            item={item}
                            play={handleModelViewer}
                            selected={selectedMorceaux}
                        />
                    ))}
                </Grid2>
            </TabsContentRessource>
        </AnatomieContext.Provider>
    );
}
