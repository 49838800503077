import Stack from "@mui/material/Stack";
import { useLoaderData, useNavigate } from "react-router-dom";
import IconButton from "@mui/material/IconButton";
import ArrowBackRoundedIcon from "@mui/icons-material/ArrowBackRounded";
import Slide from "@mui/material/Slide";
import React from "react";
import HeaderRessource from "../components/Resources/HeaderRessource";
import IllustrationRessource from "../components/Resources/IllustrationRessource";
import PlayButtonRessource from "../components/Resources/PlayButtonRessource";

export default function Ressource({ children }) {
    const [isOpen, setIsOpen] = React.useState(true);
    const data = useLoaderData();
    const navigate = useNavigate();

    function handleExit() {
        setIsOpen(false);
        navigate("..");
    }

    return (
        <Slide direction="left" in={isOpen}>
            <Stack position="relative" bgcolor={"#FFFFFF"}>
                <IconButton
                    onClick={handleExit}
                    sx={{
                        position: "absolute",
                        top: 0,
                        left: 0,
                        color: "#000",
                    }}
                >
                    <ArrowBackRoundedIcon />
                </IconButton>

                <IllustrationRessource illustration={data.coverFileName} />
                <HeaderRessource
                    name={data.name}
                    id={data.rowKey}
                    tags={data.labels.split(",")}
                />
                <PlayButtonRessource />
                {children}
            </Stack>
        </Slide>
    );
}
/*
export default function Ressource() {
    
    const chapitres = useChapitresVideo("ChapitresVideo" + video.rowKey);
    const location = useLocation();

    const [tab, setTab] = React.useState(0);
    const videoRef = React.useRef();

    const handleTabs = (event, newValue) => {
        setTab(newValue);
    };

    function handleExit() {
        setIsOpen(false);
        nav("..");
    }
    // openVideo(videoRef.current)
    React.useEffect(() => {
        location.state.play && openVideo(videoRef.current);
    }, [location]);

    React.useEffect(() => {
        videoRef && exitFullScreenVideo(videoRef);
        videoRef && nav(".", { state: { play: false } });
    }, [videoRef, nav]);

    const [viewed, setViewed] = React.useState(false);

    const handleTimeUpdate = (event) => {
        const currentTime = event.target.currentTime;

        // Si l'utilisateur a regardé plus de 10 secondes et que la vue n'a pas encore été comptée
        if (currentTime > 60 && !viewed) {
            setViewed(true);
            const action = [
                [
                    "update",
                    {
                        partitionKey: video.partitionKey,
                        rowKey: video.rowKey,
                        views: video.views ? video.views + 1 : 1,
                    },
                    "Merge",
                ],
            ];
            tableVideos.submitTransaction(action);
            //onView(videoId);  // Appelle la fonction pour compter la vue
        }
    };

    // Debug
    /*
    React.useEffect(() => console.log(timeWatched), [timeWatched]);
    React.useEffect(() => console.log(viewed), [viewed]);

    return (
        <Slide direction="left" in={isOpen}>
            <Stack position="relative" bgcolor={"#FFFFFF"}>
                <video
                    width={1}
                    onTimeUpdate={handleTimeUpdate}
                    src={getBlobVideo(video.fileName)}
                    ref={videoRef}
                    controls
                    controlsList="nodownload"
                    style={{ zIndex: -1, position: "absolute" }}
                />
                <IconButton
                    onClick={handleExit}
                    sx={{
                        position: "absolute",
                        top: 0,
                        left: 0,
                        color: "#FFF",
                    }}
                >
                    <ArrowBackRoundedIcon />
                </IconButton>

                <IllustrationRessource
                    data={{
                        categorie: "Video",
                        illustration: video.coverFileName,
                    }}
                />
                <HeaderRessource
                    data={{
                        name: video.name,
                        labels: video.labels.split(","),
                        categorie: "Video",
                        rowKey: video.rowKey,
                    }}
                />

                <Button
                    color="info"
                    variant="contained"
                    startIcon={<PlayArrowRoundedIcon />}
                    sx={{ m: 2, lineHeight: 1.1 }}
                    onClick={() => nav(".", { state: { play: true } })}
                >
                    Lecture
                </Button>

                <Box mx={2}>
                    <Tabs value={tab} onChange={handleTabs}>
                        <Tab label="Description" />
                        <Tab label="Chapitres" />
                    </Tabs>
                    <Divider sx={{ mb: 1 }} />
                    {tab === 0 ? (
                        <Typography>{video.description}</Typography>
                    ) : (
                        chapitres.map((item) => (
                            <Card
                                variant="outlined"
                                key={item.name}
                                sx={{ my: 1 }}
                            >
                                <Grid2
                                    component={CardActionArea}
                                    container
                                    xs={12}
                                    alignItems="flex-start"
                                    onClick={() =>
                                        openVideo(
                                            videoRef.current,
                                            item.startAt
                                        )
                                    }
                                >
                                    <Grid2
                                        xs={5}
                                        sx={{
                                            position: "relative",
                                            display: "flex",
                                            justifyContent: "center",
                                            alignItems: "center",
                                        }}
                                    >
                                        <IllustrationRessource
                                            height={80}
                                            data={{
                                                categorie: "Video",
                                                illustration:
                                                    item.coverFileName,
                                            }}
                                        />
                                        <PlayArrowRoundedIcon
                                            sx={{
                                                position: "absolute",
                                                color: "primary.main",
                                                fontSize: 30,
                                                opacity: 0.7,
                                                borderColor: "primary.light",
                                                borderStyle: "solid",
                                                borderWidth: 1,
                                                borderRadius: 55,
                                            }}
                                        />
                                        <Typography
                                            variant="overline"
                                            sx={{
                                                fontSize: 8,
                                                position: "absolute",
                                                bottom: 0,
                                                lineHeight: 1.1,
                                                pt: 1,
                                                width: "100%",
                                                borderTopRightRadius: 10,
                                                background:
                                                    "linear-gradient(0deg, rgba(255,255,255,0.8) 50%, rgba(255,255,255,0) 100%)",
                                            }}
                                        >
                                            Début à{" "}
                                            {item.startAt < 60
                                                ? item.startAt + " sec"
                                                : Math.floor(
                                                      item.startAt / 64
                                                  ) + " min"}
                                        </Typography>
                                    </Grid2>

                                    <Grid2 xs={7} pl={1}>
                                        <Typography
                                            variant="h6"
                                            fontSize={14}
                                            overflow="hidden"
                                            height={22}
                                            color="primary.main"
                                        >
                                            {item.name}
                                        </Typography>
                                        <Divider variant="middle" />

                                        <Typography
                                            component="p"
                                            variant="body"
                                            fontSize={12}
                                        >
                                            {item.description}
                                        </Typography>
                                    </Grid2>
                                </Grid2>
                            </Card>
                        ))
                    )}
                </Box>
            </Stack>
        </Slide>
    );
}
*/
