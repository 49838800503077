import Box from "@mui/material/Box";
import Dialog from "@mui/material/Dialog";
import Typography from "@mui/material/Typography";
import ExitToAppIcon from "@mui/icons-material/ExitToApp";
import { getBlobModele3D } from "storage/blobStorage";
import Slide from "@mui/material/Slide";
import Divider from "@mui/material/Divider";
import AccountTreeRoundedIcon from "@mui/icons-material/AccountTreeRounded";
import Button from "@mui/material/Button";
import React from "react";
import Collapse from "@mui/material/Collapse";
import Card from "@mui/material/Card";
import CardActionArea from "@mui/material/CardActionArea";
import Stack from "@mui/material/Stack";
import IllustrationRessource from "./Resources/IllustrationRessource";

export default function ModelViewer({
    open,
    handle,
    modele3D,
    listMorceaux,
    handleModele3D,
    selectedMorceaux,
}) {
    const [openNomenclature, setOpenNomenclature] = React.useState(false);

    if (modele3D)
        return (
            <Dialog
                fullScreen
                open={open}
                onClose={handle}
                TransitionComponent={Slide}
                PaperProps={{
                    sx: {
                        p: 1,
                    },
                }}
            >
                <Box>
                    <Typography
                        color="primary.main"
                        variant="h4"
                        display="flex"
                        justifyContent="space-between"
                        alignItems="center"
                    >
                        {modele3D.name}
                    </Typography>

                    <Divider />
                </Box>

                <model-viewer
                    src={getBlobModele3D(modele3D.file3D)}
                    ar
                    exposure="1.3"
                    shadow-softness="1"
                    ar-modes="webxr scene-viewer quick-look"
                    camera-controls
                    shadow-intensity="0.90"
                    style={{
                        height: "100%",
                        width: "100%",
                    }}
                />
                <Collapse in={openNomenclature} orientation="vertical">
                    <Box sx={{ mb: 20 }}>
                        <Typography color="primary.main" variant="h6">
                            Nomenclature
                        </Typography>
                        <Divider />
                        <Stack
                            direction="row"
                            flexWrap="nowrap"
                            overflow="scroll"
                            spacing={1}
                            my={1}
                        >
                            {listMorceaux.map((item) => (
                                <Card
                                    variant={"outlined"}
                                    key={item.name}
                                    sx={{
                                        borderColor:
                                            selectedMorceaux.rowKey ===
                                                item.id && "info.main",
                                        flex: "0 0 auto",
                                        height: "40vw",
                                        width: "40vw",
                                    }}
                                    //position="relative"
                                >
                                    <CardActionArea
                                        onClick={(e) => handleModele3D(item.id)}
                                    >
                                        <Typography
                                            variant="h6"
                                            fontSize={14}
                                            height={22}
                                            color="primary.main"
                                            position="absolute"
                                            top={3}
                                            left={5}
                                        >
                                            {item.name}
                                        </Typography>

                                        <IllustrationRessource
                                            height={20}
                                            illustration={item.cover}
                                        />
                                    </CardActionArea>
                                </Card>
                            ))}
                        </Stack>
                        <Divider />
                    </Box>
                </Collapse>

                <Box
                    display="flex"
                    justifyContent="space-between"
                    alignItems="center"
                    width={"100%"}
                    p={1}
                >
                    <Button
                        onClick={handle}
                        aria-label="close"
                        variant="outlined"
                        size="small"
                        startIcon={<ExitToAppIcon />}
                    >
                        Retour
                    </Button>

                    <Button
                        onClick={() => setOpenNomenclature(!openNomenclature)}
                        aria-label="close"
                        variant="outlined"
                        size="small"
                        startIcon={<AccountTreeRoundedIcon />}
                    >
                        Nomenclature
                    </Button>
                </Box>
            </Dialog>
        );
}
