import React from "react";
//Mui Components
import Collapse from "@mui/material/Collapse";
import Divider from "@mui/material/Divider";
import Stack from "@mui/material/Stack";
import Chip from "@mui/material/Chip";
//Mui Icons
import AddCircleOutlineRoundedIcon from "@mui/icons-material/AddCircleOutlineRounded";
import CancelRoundedIcon from "@mui/icons-material/CancelRounded";
//Filter Context
import { FilterContext } from "contexts/FilterContext";

const filters = [
    "CAP",
    "BP",
    "BOEUF",
    "VEAU",
    "AGNEAU",
    "VOLAILLE",
    "COUPE",
    "DÉSOSSAGE",
    "PARAGE",
    "HABILLAGE",
    "FICELAGE",
    "ABATS",
    "AIGUISAGE",
];

export default function FilterRessource() {
    const { filter, handleFilter, openFilter } =
        React.useContext(FilterContext);

    return (
        <Collapse in={openFilter} sx={{ mt: 1 }}>
            <Divider variant="middle" />
            <Stack spacing={1} direction="row" flexWrap="wrap" useFlexGap p={1}>
                {filters.map((item) => (
                    <Chip
                        key={item}
                        size="small"
                        clickable
                        sx={{ textTransform: "uppercase" }}
                        variant={filter.includes(item) ? "filled" : "outlined"}
                        label={item}
                        color="tertiary"
                        icon={
                            filter.includes(item) ? (
                                <CancelRoundedIcon />
                            ) : (
                                <AddCircleOutlineRoundedIcon />
                            )
                        }
                        onClick={() => handleFilter(item)}
                    />
                ))}
            </Stack>
            <Divider variant="middle" />
        </Collapse>
    );
}
