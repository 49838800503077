import React from "react";
import { tableService, getTable } from "storage/tableStorage";

export default function useRessourcesList() {
    const [liste, setListe] = React.useState([]);
    const [categorie, setCategorie] = React.useState(["Aucune"]);

    async function getTableListe() {
        for await (const table of tableService.listTables()) {
            if (table.name.includes("Ressources")) {
                for await (const entity of getTable(
                    table.name
                ).listEntities()) {
                    setListe((prev) => [
                        ...prev,
                        {
                            name: entity.name,
                            fileName: entity.fileName,
                            description: entity.description,
                            illustration: entity.coverFileName,
                            labels: entity.labels.split(","),
                            childrenCount: entity.childrenCount,
                            rowKey: entity.rowKey,
                            categorie: table.name.replace("Ressources", ""),
                            size: entity.size,
                            views: entity.views ? entity.views : 0,
                        },
                    ]);
                }

                setCategorie((prev) => [
                    ...prev,
                    table.name.replace("Ressources", ""),
                ]);
            }
        }
    }

    React.useEffect(() => {
        !liste.length && getTableListe();
    }, [liste]);

    return { liste, categorie };
}
