//Mui Components
import Button from "@mui/material/Button";
//Mui Icons
import PlayArrowRoundedIcon from "@mui/icons-material/PlayArrowRounded";
import ViewInArSharpIcon from "@mui/icons-material/ViewInArSharp";
//Nav Router
import { useLocation, useNavigate } from "react-router-dom";

export default function PlayButtonRessource() {
    const navigate = useNavigate();
    const location = useLocation().pathname;

    return (
        <Button
            color="info"
            variant="contained"
            startIcon={
                location.includes("Video") ? (
                    <PlayArrowRoundedIcon />
                ) : (
                    <ViewInArSharpIcon />
                )
            }
            sx={{ m: 2, lineHeight: 1.1 }}
            onClick={() => navigate(".", { state: { play: true } })}
        >
            {location.includes("Video") ? "Lecture" : "Visionner"}
        </Button>
    );
}
