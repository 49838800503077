//Mui Components
import CardHeader from "@mui/material/CardHeader";
//Custom Components
import ButtonActionsRessources from "./ButtonActionsRessource";

export default function HeaderRessource({ name, tags, id }) {
    return (
        <CardHeader
            title={name}
            subheader={tags.map((item, index) =>
                index === 0 ? item : " - " + item
            )}
            action={<ButtonActionsRessources id={id} />}
            sx={{
                px: 1,
                pt: 0.5,
                pb: 0,
                "& .MuiCardHeader-title": {
                    fontSize: 20,
                    fontWeight: "800",
                    mt: 0.2,
                    lineHeight: 1.1,
                    color: "primary.main",
                },
                "& .MuiCardHeader-subheader": {
                    fontSize: 13,
                    lineHeight: 0.6,
                    color: "secondary.dark",
                    my: 0.5,
                    textTransform: "lowercase",
                },
            }}
            //action={<ActionRessource data={data} />}
        />
    );
}
