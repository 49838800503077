import React from "react";
// MUI Components
import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
//Content Img
import logo from "./content/openLogo.svg";
import Typography from "@mui/material/Typography";
import Zoom from "@mui/material/Zoom";

export default function OpeningApp() {
	const [open, setOpen] = React.useState(true);
	React.useEffect(() => {
		open && setTimeout(() => setOpen(false), 6500);
	}, [open]);

	const [showTitle, setShowTitle] = React.useState(false);
	const [style, setStyle] = React.useState({ opacity: 1 });
	React.useEffect(() => {
		!showTitle && setTimeout(() => setShowTitle(true), 2800);
		showTitle &&
			setStyle({
				opacity: 0,
				transitionProperty: "opacity",
				transitionDuration: "1.5s",
				transitionDelay: "2s",
			});
	}, [showTitle]);

	return (
		<Backdrop
			open={open}
			transitionDuration={0}
			style={style}
			sx={{
				//bgcolor: "primary.main",
				zIndex: 99999,
				flexDirection: "column",
				background: "#182156",
			}}
			unmountOnExit
		>
			<Zoom in={true} style={{ transitionDuration: "2.5s" }}>
				<Box
					sx={{
						width: "100%",
						maxHeight: "100%",
						p: 5,
						display: "flex",
						flexDirection: "column",
						justifyContent: "center",
					}}
				>
					<img alt="logo EPB" src={logo} />
					<Typography
						sx={{
							textShadow:
								"rgba(0, 0, 0, 0.325) 0px 3.1px 13.175px",
							color: "white",
							fontSize: 35,
							my: 2,
							fontWeight: "700",
						}}
					>
						Portail de formation
					</Typography>
				</Box>
			</Zoom>
		</Backdrop>
	);
}
